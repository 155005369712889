<template>
  <v-dialog v-model="modalVisibility" width="60vw" @click:outside="closeModal">
    <v-card class="pa-6">
      <p class="text-2xl mb-8">
        {{ isEditing ? `Editer une note` : "Créer un nouvelle note" }}
      </p>
      <v-row class="mx-1">
        <v-card-text>
          <v-form ref="addEditForm">
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="title"
                  label="Titre"
                  outlined
                  :rules="[required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  v-model="priority"
                  :items="priorities"
                  item-text="name"
                  item-value="id"
                  label="Priorité"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  v-model="impact"
                  :items="impacts"
                  item-text="name"
                  item-value="id"
                  label="Impact attendu"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="taskUrl"
                  label="URL de la tâche"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Description"
                  outlined
                  rows="3"
                  v-model="description"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-menu
                  ref="menuDateNotified"
                  v-model="dateNotifiedMenu"
                  max-width="290px"
                  min-width="auto"
                  style="top: 228px"
                  :close-on-content-click="false"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateNotified"
                      label="Date de détection"
                      :append-icon="icons.calendar"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-if="dateNotifiedMenu"
                    v-model="dateNotified"
                    scrollable
                    @input="dateNotifiedMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6">
                <v-menu
                  ref="menuTimeNotified"
                  v-model="timeNotifiedMenu"
                  max-width="290px"
                  style="top: 228px"
                  :close-on-content-click="false"
                  :return-value.sync="timeNotified"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="timeNotified"
                      label="Heure de détection"
                      :append-icon="icons.clock"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[required]"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeNotifiedMenu"
                    v-model="timeNotified"
                    format="24hr"
                    full-width
                    @click:minute="$refs.menuTimeNotified.save(timeNotified)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row v-if="status === 2">
              <v-col cols="12" sm="6">
                <v-menu
                  ref="menuDateResolved"
                  v-model="dateResolvedMenu"
                  max-width="290px"
                  min-width="auto"
                  style="top: 228px"
                  :close-on-content-click="false"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateResolved"
                      label="Date de résolution"
                      :append-icon="icons.calendar"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-if="dateResolvedMenu"
                    v-model="dateResolved"
                    scrollable
                    @input="dateResolvedMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6">
                <v-menu
                  ref="menuTimeResolved"
                  v-model="timeResolvedMenu"
                  max-width="290px"
                  style="top: 228px"
                  :close-on-content-click="false"
                  :return-value.sync="timeResolved"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="timeResolved"
                      label="Heure de résolution"
                      :append-icon="icons.clock"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeResolvedMenu"
                    v-model="timeResolved"
                    format="24hr"
                    full-width
                    @click:minute="$refs.menuTimeResolved.save(timeResolved)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  v-model="status"
                  :items="statuses"
                  item-text="name"
                  item-value="id"
                  label="Statut"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  v-model="site"
                  :items="sites"
                  label="Site"
                  item-text="name"
                  item-value="id"
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  outlined
                  v-model="pageTypes"
                  :items="pageTypeList"
                  multiple
                  label="Type de page"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  outlined
                  v-model="devices"
                  :items="deviceList"
                  label="Device"
                  item-text="name"
                  item-value="id"
                  multiple
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  outlined
                  v-model="metrics"
                  :items="metricsList"
                  label="Métrique"
                  item-text="name"
                  item-value="id"
                  multiple
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn class="mr-4" color="primary" @click.native="saveIssue">
                  Enregistrer</v-btn
                >
                <v-btn color="secondary" outlined @click.native="closeModal">
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "@core/utils/validation";
import axios from "@axios";
import {
  mdiClockTimeFourOutline,
  mdiCalendar,
  mdiEye,
  mdiEyeOff,
} from "@mdi/js";
import store from "@/store";
import useAxios from "@/hooks/useAxios";
import {
  impacts,
  priorities,
  statuses,
} from "@/components/web-perf/cwv/utils/utils";

export default {
  name: "AddEditNoteModal",
  setup() {
    const { axiosGet, axiosPost } = useAxios();

    return {
      axiosGet,
      axiosPost,
    };
  },
  data() {
    return {
      required,
      title: null,
      priority: 0,
      priorities,
      impact: 0,
      impacts,
      taskUrl: null,
      description: null,
      isEditing: false,
      dateNotified: null, // new Date().toISOString().slice(0, 10),
      timeNotified: null, // new Date().toLocaleTimeString().slice(0, 5),
      dateResolved: null,
      timeResolved: null,
      timeNotifiedMenu: false,
      dateNotifiedMenu: false,
      timeResolvedMenu: false,
      dateResolvedMenu: false,
      status: 0,
      statuses,
      pageTypes: [],
      devices: [],
      deviceList: [
        { id: "15", name: "Mobile" },
        { id: "16", name: "Desktop" },
        { id: "17", name: "Tablette" },
      ],
      metrics: [],
      metricsList: [
        { id: "cls", name: "CLS" },
        { id: "fcp", name: "FCP" },
        { id: "inp", name: "INP" },
        { id: "lcp", name: "LCP" },
        { id: "ttfb", name: "TTFB" },
      ],
      site: null,
      icons: {
        clock: mdiClockTimeFourOutline,
        calendar: mdiCalendar,
        eye: mdiEye,
        eyeOff: mdiEyeOff,
      },
    };
  },
  computed: {
    modalVisibility: {
      get: () => store.getters["webPerf/getIssueModalVisibility"],
      set: (value) =>
        store.dispatch("webPerf/updateIssueModalVisibility", value),
    },
    sites() {
      return store.getters["common/getSiteList"];
    },
    pageTypeList() {
      return store.getters["webPerf/getPageTypeList"];
    },
    selectedIssueId() {
      return store.getters["webPerf/getSelectedIssueId"];
    },
  },
  methods: {
    closeModal() {
      store.dispatch("webPerf/updateSelectedIssueId", null);
      store.dispatch("webPerf/updateIssueModalVisibility", false);
    },
    async getIssue(pk) {
      return await this.axiosGet(`/web-perf/cwv-issues/${pk}`);
    },
    async saveIssue() {
      if (!(await this.$refs.addEditForm.validate())) {
        return;
      }
      const note = {
        title: this.title,
        impact: this.impact,
        priority: this.priority,
        task_url: this.taskUrl,
        description: this.description,
        date_notified: this.dateNotified
          ? `${this.dateNotified} ${this.timeNotified}`
          : null,
        date_resolved:
          this.status === 2 && this.dateResolved
            ? `${this.dateResolved} ${this.timeResolved}`
            : null,
        status: this.status,
        content_types: this.pageTypes.join(",") || null,
        devices: this.devices.join(",") || null,
        metrics: this.metrics.join(",") || null,
        site_id: this.site,
      };
      if (this.isEditing) {
        await axios.put(`/web-perf/cwv-issues/${this.selectedIssueId}`, note);
      } else {
        await this.axiosPost("/web-perf/cwv-issues", note);
      }
      this.$store.dispatch("webPerf/getIssues");
      this.closeModal();
    },
  },
  watch: {
    async modalVisibility(value) {
      if (value) {
        this.isEditing = !!this.selectedIssueId;
        if (this.isEditing) {
          const { data: issue } = await this.getIssue(this.selectedIssueId);
          this.title = issue.title;
          this.impact = issue.impact;
          this.priority = issue.priority;
          this.taskUrl = issue.task_url;
          this.description = issue.description;
          this.dateNotified = issue.date_notified.slice(0, 10);
          this.timeNotified = new Date(issue.date_notified).toLocaleTimeString(
            "fr-FR"
          );
          this.dateResolved = issue.date_resolved
            ? issue.date_resolved.slice(0, 10)
            : null;
          this.timeResolved = issue.date_resolved
            ? new Date(issue.date_resolved).toLocaleTimeString("fr-FR")
            : null;
          this.status = issue.status;
          this.pageTypes = issue.content_types?.split(",") || [];
          this.devices = issue.devices?.split(",") || [];
          this.metrics = issue.metrics?.split(",") || [];
          this.site = issue.site_id;
        } else {
          this.title = null;
          this.impact = 0;
          this.priority = 0;
          this.taskUrl = null;
          this.description = null;
          this.dateNotified = null; // new Date().toISOString().slice(0, 10);
          this.timeNotified = null; //new Date().toLocaleTimeString().slice(0, 5);
          this.dateResolved = null;
          this.timeResolved = null;
          this.status = 0;
          this.pageTypes = [];
          this.devices = [];
          this.metrics = [];
          this.site = null;
        }
      }
    },
  },
};
</script>
