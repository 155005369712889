export const impacts = [
  { id: 0, name: "Bas" },
  { id: 1, name: "Moyen" },
  { id: 2, name: "Haut" },
];

export const impactsColors = [
  { id: 0, color: "#055a09" },
  { id: 1, color: "#e77000" },
  { id: 2, color: "#cc0000" },
];

export const priorities = [
  { id: 0, name: "Basse" },
  { id: 1, name: "Moyenne" },
  { id: 2, name: "Haute" },
];

export const prioritiesColors = [
  { id: 0, color: "#055a09" },
  { id: 1, color: "#e77000" },
  { id: 2, color: "#cc0000" },
];

export const statuses = [
  { id: 0, name: "À faire" },
  { id: 1, name: "En cours" },
  { id: 2, name: "Terminé" },
  { id: 3, name: "Bloqué" },
];

export const statusesColors = [
  { id: 0, color: "#1b35ce" },
  { id: 1, color: "#e09500" },
  { id: 2, color: "#045d04" },
  { id: 3, color: "#800505" },
];

export const getStatusColor = (statusId) => {
  return statusesColors.find((status) => status.id === statusId).color;
};

export const getStatusName = (statusId) => {
  return statuses.find((status) => status.id === statusId).name;
};
