<template>
  <v-dialog v-model="modalVisibility" width="60vw" @click:outside="closeModal">
    <v-card class="pa-4">
      <p class="text-2xl ma-5 mb-9">Etes vous sur de vouloir supprimer?</p>
      <v-row class="mx-1 my-3">
        <v-card-text>
          <v-form ref="confirmationForm">
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="deleteIssue"
                  >Supprimer
                </v-btn>
                <v-btn color="secondary" outlined @click.native="closeModal">
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "@axios";

export default {
  name: "DeleteIssueModal",
  methods: {
    closeModal() {
      this.$store.dispatch("webPerf/updateSelectedIssueId", null);
      this.$store.dispatch("webPerf/updateDeleteIssueModalVisibility", false);
    },
    async deleteIssue() {
      const { status } = await axios.delete(
        `/web-perf/cwv-issues/${this.$store.getters["webPerf/getSelectedIssueId"]}`
      );
      await this.$store.dispatch("webPerf/getIssues");
      this.closeModal();
    },
  },
  computed: {
    modalVisibility: {
      get() {
        return this.$store.getters["webPerf/getDeleteIssueModalVisibility"];
      },
      set(value) {
        this.$store.dispatch("webPerf/updateDeleteIssueModalVisibility", value);
      },
    },
  },
};
</script>
