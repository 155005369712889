<template>
  <v-card class="mb-6 pa-2">
    <v-row class="ma-3">
      <v-col cols="4">
        <site-filter store="webPerf"></site-filter>
      </v-col>
      <v-col cols="4">
        <device-filter store="webPerf"></device-filter>
      </v-col>
      <v-col cols="4">
        <page-type-filter store="webPerf"></page-type-filter>
      </v-col>
      <v-col cols="4">
        <metric-filter store="webPerf"></metric-filter>
      </v-col>
      <v-spacer />
      <v-col cols="2">
        <apply-filters :disabled="disabled" outlined color="secondary" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateFilter from "@/components/common/filters/DateFilter.vue";
import SiteFilter from "@/components/common/filters/SiteFilter.vue";
import DeviceFilter from "@/components/common/filters/DeviceFilter.vue";
import MetricFilter from "@/components/web-perf/cwv/filters/filters/MetricFilter.vue";
import PageTypeFilter from "@/components/web-perf/cwv/filters/filters/PageTypeFilter.vue";
import EvolutionFilter from "@/components/web-perf/cwv/filters/filters/EvolutionFilter.vue";
import CategoryFilter from "@/components/common/filters/CategoryFilter.vue";

export default {
  name: "Filters",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    devFilterVisible: {
      type: Boolean,
      default: false,
    },
    rangeDate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ApplyFilters,
    DateFilter,
    SiteFilter,
    EvolutionFilter,
    DeviceFilter,
    CategoryFilter,
    MetricFilter,
    PageTypeFilter,
  },
  computed: {
    totalEventsJ1: {
      get() {
        return this.$store.getters["webPerf/getTotalEvents"];
      },
      set(value) {
        if (isNaN(value) || value === "") {
          value = 0;
        }
        this.$store.commit("webPerf/updateTotalEvents", parseInt(value));
      },
    },
    totalPostsJ1: {
      get() {
        return this.$store.getters["webPerf/getTotalPosts"];
      },
      set(value) {
        if (isNaN(value) || value === "") {
          value = 0;
        }
        this.$store.commit("webPerf/updateTotalPosts", parseInt(value));
      },
    },
    totalEventsJ2: {
      get() {
        return this.$store.getters["webPerf/getTotalEventsJ2"];
      },
      set(value) {
        if (isNaN(value) || value === "") {
          value = 0;
        }
        this.$store.commit("webPerf/updateTotalEventsJ2", parseInt(value));
      },
    },
    totalPostsJ2: {
      get() {
        return this.$store.getters["webPerf/getTotalPostsJ2"];
      },
      set(value) {
        if (isNaN(value) || value === "") {
          value = 0;
        }
        this.$store.commit("webPerf/updateTotalPostsJ2", parseInt(value));
      },
    },
    disabled: {
      get() {
        return this.$store.getters["common/getApplyFilters"];
      },
      set(value) {
        this.$store.commit("common/updateApplyFilters", value);
      },
    },
  },
  watch: {},
};
</script>

<style scoped></style>
