<template>
  <v-card>
    <v-card-text>
      <v-data-table
        locale="fr-FR"
        :page="options.page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="items"
        :sort-by="'date'"
        :sort-desc="true"
        :options.sync="options"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        :items-per-page="options.itemsPerPage"
      >
        <template v-slot:item.title="{ item }">
          <div style="line-height: 1.4" class="my-3">
            <span style="font-weight: bold" class="blue">
              {{ item.title }}
            </span>

            <HoverTooltip v-if="item.task_url">
              <template v-slot:tooltip>
                <div>{{ item.task_url }}</div>
              </template>
              <a :href="item.task_url" target="_blank">
                <v-icon color="primary" size="16" class="mx-1">{{
                  icons.mdiOpenInNew
                }}</v-icon></a
              >
            </HoverTooltip>
            <HoverTooltip v-if="item.description">
              <template v-slot:tooltip>
                <div>{{ item.description }}</div>
              </template>
              <v-icon color="primary" size="16" class="mx-1">{{
                icons.mdiInformation
              }}</v-icon>
            </HoverTooltip>
            <div>
              <span
                style="font-size: 10px; line-height: 1em"
                v-if="item.content_types"
                v-for="type in item.content_types"
              >
                #{{ type }}
              </span>
            </div>
            <div>
              <v-chip
                v-if="item.devices"
                v-for="device in item.devices"
                :key="device.id"
                x-small
                class="mr-2 blue bg-white border-blue"
              >
                {{ device }}
              </v-chip>

              <v-chip
                v-if="item.metrics"
                v-for="metric in item.metrics"
                x-small
                class="mr-2 white bg-blue"
              >
                {{ metric }}
              </v-chip>
            </div>
          </div>
        </template>

        <template v-slot:item.impact="{ item }">
          <span
            :style="{
              color: impactsColors[item.impact].color,
            }"
          >
            {{ impacts[item.impact].name }}
          </span>
        </template>

        <template v-slot:item.priority="{ item }">
          <span
            :style="{
              color: prioritiesColors[item.priority].color,
            }"
          >
            {{ priorities[item.priority].name }}
          </span>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip :color="getStatusColor(item.status)" dark small>
            {{ getStatusName(item.status) }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn icon color="primary">
            <v-icon v-on:click="openAddEditIssueModal(item.id)">
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
          <v-btn icon color="error">
            <v-icon v-on:click="openDeleteIssueModal(item.id)">
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<style scoped></style>
<script>
import useAxios from "@/hooks/useAxios";
import {
  mdiInformation,
  mdiPencil,
  mdiTrashCan,
  mdiEye,
  mdiEyeOff,
  mdiOpenInNew,
} from "@mdi/js";
import debounce from "lodash/debounce";
import { formatDateTimeFrench } from "@/utils/dates";
import {
  getStatusColor,
  getStatusName,
  impacts,
  impactsColors,
  priorities,
  prioritiesColors,
  statuses,
  statusesColors,
} from "@/components/web-perf/cwv/utils/utils";
import HoverTooltip from "@/components/common/HoverTooltip.vue";
export default {
  name: "CwvDailyDebugTable",
  components: { HoverTooltip },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  created() {
    this.debouncedGetData();
  },
  data() {
    return {
      search: "",
      numberOfPages: 0,
      deviceList: [
        { id: 15, name: "Mobile" },
        { id: 16, name: "Desktop" },
        { id: 17, name: "Tablette" },
      ],
      impacts,
      impactsColors,
      priorities,
      prioritiesColors,
      statuses,
      statusesColors,
      items: [],
      loading: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
      },
      icons: {
        mdiInformation,
        mdiPencil,
        mdiTrashCan,
        mdiEye,
        mdiEyeOff,
        mdiOpenInNew,
      },
      headers: [
        {
          text: "Site",
          value: "site_name",
          align: "start",
        },
        {
          text: "Titre",
          value: "title",
          width: "30%",
        },
        {
          text: "Statut",
          value: "status",
        },
        {
          text: "Priorité",
          value: "priority",
        },
        {
          text: "Impact attendu",
          value: "impact",
        },
        {
          text: "Date de détection",
          value: "date_notified",
        },
        {
          text: "Date de résolution",
          value: "date_resolved",
        },
        // {
        //   text: "Types de pages",
        //   value: "content_types",
        // },
        // {
        //   text: "Appareils",
        //   value: "devices",
        // },
        // {
        //   text: "Metriques",
        //   value: "metrics",
        // },
        {
          text: "",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    formatDateTimeFrench,
    getStatusColor,
    getStatusName,
    shortenText(text, maxLength) {
      return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    },
    async openAddEditIssueModal(pk) {
      await this.$store.dispatch("webPerf/updateSelectedIssueId", pk);
      await this.$store.dispatch("webPerf/updateIssueModalVisibility", true);
    },
    openDeleteIssueModal(pk) {
      this.$store.dispatch("webPerf/updateSelectedIssueId", pk);
      this.$store.dispatch("webPerf/updateDeleteIssueModalVisibility", true);
    },
    getDeviceNames: (idString, deviceList) =>
      !idString
        ? ""
        : idString
            .split(",")
            .map((id) =>
              deviceList.find((device) => device.id === parseInt(id.trim()))
            )
            .filter(Boolean)
            .map((device) => device.name),
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      this.loading = true;
      await this.$store.dispatch("webPerf/getIssues");
      this.loading = false;
      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      await this.$store.dispatch("common/updateApplyFilters", false);
    },
  },
  computed: {
    async issues() {
      return await this.$store.getters["webPerf/getIssues"];
    },
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["webPerf/getSites"];
    },
    pageTypes() {
      return this.$store.getters["webPerf/getPageTypes"];
    },
    devices() {
      return this.$store.getters["webPerf/getDevices"];
    },
    metrics() {
      return this.$store.getters["webPerf/getMetrics"];
    },
  },
  watch: {
    applyFilters(oldValue, newValue) {
      if (oldValue !== newValue && oldValue) {
        this.debouncedGetData();
      }
    },
    async issues() {
      this.items = await this.issues;
      this.items.map((item) => {
        if (item.date_notified) {
          item.date_notified = this.formatDateTimeFrench(item.date_notified);
        }
        if (item.date_resolved) {
          item.date_resolved = this.formatDateTimeFrench(item.date_resolved);
        }
        item.metrics = item.metrics?.toUpperCase().split(",")
          ? item.metrics?.toUpperCase().split(",")
          : [];
        item.devices = this.getDeviceNames(item.devices, this.deviceList)
          ? this.getDeviceNames(item.devices, this.deviceList)
          : [];
        item.content_types = item.content_types?.split(",")
          ? item.content_types?.split(",")
          : [];
        return item;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.blue {
  color: #11396e;
}
.bg-blue {
  background-color: #11396e !important;
}
.white {
  color: white;
}
.bg-white {
  background-color: white !important;
}
.border-blue {
  border: 1px solid #11396e !important;
}
</style>
