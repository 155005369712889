var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"locale":"fr-FR","page":_vm.options.page,"pageCount":_vm.numberOfPages,"headers":_vm.headers,"items":_vm.items,"sort-by":'date',"sort-desc":true,"options":_vm.options,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [10, 25, 50, 100],
      },"items-per-page":_vm.options.itemsPerPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"my-3",staticStyle:{"line-height":"1.4"}},[_c('span',{staticClass:"blue",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(item.title)+" ")]),(item.task_url)?_c('HoverTooltip',{scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('div',[_vm._v(_vm._s(item.task_url))])]},proxy:true}],null,true)},[_c('a',{attrs:{"href":item.task_url,"target":"_blank"}},[_c('v-icon',{staticClass:"mx-1",attrs:{"color":"primary","size":"16"}},[_vm._v(_vm._s(_vm.icons.mdiOpenInNew))])],1)]):_vm._e(),(item.description)?_c('HoverTooltip',{scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('div',[_vm._v(_vm._s(item.description))])]},proxy:true}],null,true)},[_c('v-icon',{staticClass:"mx-1",attrs:{"color":"primary","size":"16"}},[_vm._v(_vm._s(_vm.icons.mdiInformation))])],1):_vm._e(),_c('div',_vm._l((item.content_types),function(type){return (item.content_types)?_c('span',{staticStyle:{"font-size":"10px","line-height":"1em"}},[_vm._v(" #"+_vm._s(type)+" ")]):_vm._e()}),0),_c('div',[_vm._l((item.devices),function(device){return (item.devices)?_c('v-chip',{key:device.id,staticClass:"mr-2 blue bg-white border-blue",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(device)+" ")]):_vm._e()}),_vm._l((item.metrics),function(metric){return (item.metrics)?_c('v-chip',{staticClass:"mr-2 white bg-blue",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(metric)+" ")]):_vm._e()})],2)],1)]}},{key:"item.impact",fn:function(ref){
      var item = ref.item;
return [_c('span',{style:({
            color: _vm.impactsColors[item.impact].color,
          })},[_vm._v(" "+_vm._s(_vm.impacts[item.impact].name)+" ")])]}},{key:"item.priority",fn:function(ref){
          var item = ref.item;
return [_c('span',{style:({
            color: _vm.prioritiesColors[item.priority].color,
          })},[_vm._v(" "+_vm._s(_vm.priorities[item.priority].name)+" ")])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getStatusName(item.status))+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"}},[_c('v-icon',{on:{"click":function($event){return _vm.openAddEditIssueModal(item.id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1),_c('v-btn',{attrs:{"icon":"","color":"error"}},[_c('v-icon',{on:{"click":function($event){return _vm.openDeleteIssueModal(item.id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }