<template>
  <div>
    <div class="d-flex flex-row mb-2">
      <p class="text-2xl mb-6">Suivi des tickets</p>
      <v-col class="text-right">
        <v-btn color="primary" @click.native="openAddEditIssueModal">
          <v-icon class="mr-3">{{ icons.mdiPencilBox }}</v-icon
          >Ajouter un ticket</v-btn
        >
      </v-col>
    </div>
    <Filters />
    <CwvIssuesTable />
    <add-edit-issue-modal />
    <delete-issue-modal />
  </div>
</template>

<script>
import { mdiPlusBox } from "@mdi/js";
import Filters from "@/components/web-perf/cwv/cwv-issues/Filters.vue";
import CwvIssuesTable from "@/components/web-perf/cwv/cwv-issues/tables/IssuesTable.vue";
import AddEditIssueModal from "@/components/web-perf/cwv/cwv-issues/modals/AddEditIssueModal.vue";
import DeleteIssueModal from "@/components/web-perf/cwv/cwv-issues/modals/DeleteIssueModal.vue";

export default {
  name: "CwvNotes",
  components: {
    AddEditIssueModal,
    DeleteIssueModal,
    CwvIssuesTable,
    Filters,
  },
  data() {
    return {
      icons: {
        mdiPlusBox,
      },
    };
  },
  methods: {
    openAddEditIssueModal() {
      this.$store.dispatch("webPerf/updateIssueModalVisibility", true);
    },
  },
  watch: {},
};
</script>
<style scoped></style>
